import { getRequest, postRequest } from './cmsIndex'
// 获取新闻动态分页
export const getNewsByPage = (data, successCallback, failureCallback) => {
  postRequest('/news/newsByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除新闻
export const deleteNews = (id, successCallback, failureCallback) => {
  postRequest('/news/newsDelete', { id }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取新闻详情
export const getNewsByDetail = (id, successCallback, failureCallback) => {
  getRequest(`/news/newsByDetail?id=${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加或修改新闻
export const addAndEditNews = (data, successCallback, failureCallback) => {
  postRequest(data.id ? '/news/newsUpdate' : '/news/newsAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取客户留言分页
export const getCustMessage = (data, successCallback, failureCallback) => {
  postRequest('/custMessage/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除留言
export const deleteCustMessage = (id, successCallback, failureCallback) => {
  postRequest('/custMessage/delete', { id }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 处理留言
export const handleCustMessage = (data, successCallback, failureCallback) => {
  postRequest('/custMessage/handle', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
